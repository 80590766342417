import store from '../store';

export default [
    //메인
    {
        path: '/en/index.do',
        component: () => import('../views/en/main/MainView.vue'),
        children: [
            {
                path: '/en',
                component: () => import('../views/en/main/MainView.vue')
            }
        ],
        beforeEnter: (to, from, next) => {
            if (window.location.pathname == '/en' || window.location.pathname == '/EN/index.do') {
                window.location.pathname = '/en/index.do';
            } else {
                return next();
            }
        }
    },
    //통합검색
    {
        path: '/en/search.do',
        component: () => import('../views/en/main/SearchView.vue')
    },
    //교실 타입별 전체 교수진 목록
    {
        path: '/en/about/:lgrpCd/class/list.do',
        component: () => import('../views/en/class/ClassProfessorListView.vue')
    },
    //교실 정보
    {
        path: '/en/about/:lgrpCd/class/view.do',
        component: () => import('../views/en/class/ClassDetailView.vue')
    },
    //교수님 상세정보
    {
        path: '/en/professor/detail.do',
        component: () => import('../views/en/class/ProfessorDetailView.vue')
    },
    //Admissions Application
    {
        path: '/en/globalku/admissions/application.do',
        component: () => import('../views/en/admissions/ApplicationView.vue')
    },
    // contents
    {
        path: '/en/:path1/:path2.do',
        component: () => import('../views/en/contents/ContentsView.vue'),
        children: [
            {
                path: '/en/:path1/:path2/:path3.do',
                component: () => import('../views/en/contents/ContentsView.vue')
            }
        ]
    },
    {
        path: '/en/:path1/:path2/list.do',
        component: () => import('../views/en/boards/BoardListView.vue'),
        children: [
            {
                path: '/en/:path1/:path2/:path3/list.do',
                component: () => import('../views/en/boards/BoardListView.vue')
            }
        ]
    },
    {
        path: '/en/:path1/:path2/view.do',
        component: () => import('../views/en/boards/BoardDetailView.vue'),
        children: [
            {
                path: '/en/:path1/:path2/:path3/view.do',
                component: () => import('../views/en/boards/BoardDetailView.vue')
            }
        ]
    },
    //입력폼
    {
        path: '/en/student/input.do',
        component: () => import('../views/en/input/InputView.vue')
    },
    //입력폼 완료화면
    {
        path: '/en/student/inputCompleteView.do',
        component: () => import('../views/en/input/InputCompleteView.vue')
    }
];
